import React from 'react';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from 'core/layout';
import MetaTags from 'core/meta-tags/index';
import Pagination from 'atomic-design/organisms/pagination';
import BlogEntries from 'atomic-design/organisms/blog-entries';
import BlogCategories from 'atomic-design/molecules/blog-categories';
import PageSection from 'atomic-design/atoms/page-section';
import theme from 'styles/theme';
import PageHeader from 'atomic-design/atoms/page-header';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allContentfulPage(filter: { url: { eq: "blog" } }) {
      edges {
        node {
          headerTitle {
            json
          }
          headerSubtitle
          metatagImage {
            file {
              url
            }
          }
          metatagTitle
          metatagDescription
        }
      }
    }

    allContentfulBlogPost(
      sort: { fields: publishedDate, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          subtitle
          slug
          publishedDate
          excerpt {
            excerpt
          }
          category {
            name
            slug
          }
          hero {
            contentful_id
          }
        }
      }
    }
  }
`;
const Blog = props => {
  const { data, location } = props;
  const { currentPage, numPages } = props.pageContext;
  const pageHeaderData = data.allContentfulPage.edges[0].node;
  const metatagImage = data.allContentfulPage.edges[0].node.metatagImage.file.url;
  const { metatagDescription } = data.allContentfulPage.edges[0].node;
  const { metatagTitle } = data.allContentfulPage.edges[0].node;

  return (
    <Layout path={location.pathname} bodyType="xWide">
      <MetaTags
        title={metatagTitle}
        description={metatagDescription}
        charSet="utf-8"
        image={metatagImage}
        viewport="width=device-width, initial-scale=1"
        type="website"
      />

      <PageSection>
        <PageHeader
          title={pageHeaderData.headerSubtitle}
          subTitle={documentToReactComponents(pageHeaderData.headerTitle.json)}
          color={theme.newTheme.color.gradient.text}
        />
      </PageSection>

      <PageSection padding={`0 0 ${theme.newTheme.spacing(22)} 0`} mobilePadding="0">
        <BlogCategories showAll border />
      </PageSection>

      <PageSection
        padding={`${theme.newTheme.spacing(5)} 0 ${theme.newTheme.spacing(20)} 0`}
        mobilePadding={`0 0 ${theme.newTheme.spacing(15)} 0`}
      >
        <BlogEntries posts={data.allContentfulBlogPost.edges} />
      </PageSection>
      <PageSection margin={`${theme.newTheme.spacing(4)} 0 ${theme.newTheme.spacing(12)} 0`}>
        <Pagination to="/blog" toPage="/blog/page/" numPages={numPages} currentPage={currentPage} />
      </PageSection>
    </Layout>
  );
};

Blog.propTypes = {
  currentPage: PropTypes.number,
  numPages: PropTypes.number,
};

Blog.defaultProps = {
  currentPage: null,
  numPages: null,
};

export default Blog;
